import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SharedLoadingIndicatorContextProvider } from '../app/components/contexts/SharedLoadingIndicatorContextProvider'
import { PageNavigationLoadingTracker } from '../app/components/PageNavigationLoadingTracker'
import '../app/styles/globals.sass'

const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<SharedLoadingIndicatorContextProvider>
			<QueryClientProvider client={queryClient}>
				<PageNavigationLoadingTracker />
				<Component {...pageProps} />
			</QueryClientProvider>
		</SharedLoadingIndicatorContextProvider>
	)
}
export default MyApp
